import { inject, Injectable, Injector } from '@angular/core';
import { faro, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Logger {
    private injector = inject(Injector);

    constructor() {
        if (environment.environment !== 'local') {
            initializeFaro({
                url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/da79791653e28fa1c84c480718401a7e',
                app: {
                    name: `vln-${ environment.environment }`,
                    version: '1.0.0',
                    environment: 'production',
                    namespace: 'vln',
                },
                trackWebVitalsAttribution: true,
                instrumentations: [
                    ...getWebInstrumentations({
                        captureConsole: true,
                        enablePerformanceInstrumentation: true,
                    }),
                    new TracingInstrumentation(),
                ],
            });
        }
    }

    public error(message: string, err: any) {
        if (environment.environment !== 'production') {
            console.info(message, err);
        }

        this.pushError(message, err);
    };

    public apiError(message: string, err: any) {
        this.pushError(message, err);
        this.toastr.error('Something went wrong. Please try again later.');
        console.debug('API Error | ', message, err);
    }

    public clientError(message: string, err: any) {
        this.pushError('Client Error | ' + message, err);
        this.toastr.error('Something went wrong. Please try again later.');
        console.debug('Client Error | ', message, err);
    }

    private pushError(message: string, err: any) {
        const wrapperError = new Error(message);
        wrapperError.stack = err.stack

        faro.api.pushError(wrapperError);
    }

    private get toastr(): ToastrService {
        return this.injector.get(ToastrService);
    }

}
